<template>
  <div class="relative pr-5">
     <button @click="toggle(faq)"
             class="bg-triangles-pattern-green py-4 px-2 md:px-4 rounded-none flex items-center justify-center text-white absolute right-0 bottom-0 h-full z-20">
       <i :class="activeFaq === faq ? 'fa-arrow-down' : 'fa-arrow-right'" class="fas"></i>
     </button>
     <span class="absolute w-full h-full -inset-1.5 bg-black bg-opacity-10 blur-md"></span>
     <div class="relative w-full p-6 pb-3 bg-white">
       <h6 class="text-xl text-agra-green-dark sm:text-2xl xl:pr-20" @click="toggle(faq)">
         {{ faq.question }}
       </h6>
       <div :class="activeFaq === faq ? '': 'overflow-hidden'"
            :style="activeFaq === faq ? 'max-height:fit-content' : ''"
            class="max-h-0 duration-500 transition-all pr-6 answer">
         <!--<p class="my-6" v-html="faq.answer"></p>-->
           <VueMarkdownIt :source='faq.answer' />
       </div>
       <div class="flex flex-wrap mt-4">
         <span v-for="(tag, index) in faq.tags"
               :key="index"
               class="bg-triangles-pattern-green text-xs text-white px-4 py-1 rounded-full mr-3 mb-3 block">
             {{ tag }}
         </span>
       </div>
     </div>
   </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import VueMarkdownIt from 'vue3-markdown-it';

export default {
  props: ['faq'],
  computed: {
    ...mapState([
      'activeFaq'
    ])
  },
components: {
    VueMarkdownIt
},
  methods: {
    ...mapMutations([
      'setActiveFaq'
    ]),
    toggle(faq) {
      this.setActiveFaq(!this.activeFaq ? faq : null);
    }
  }
}
</script>
<style>
    .answer > div > p > a {
        color: green;
    }
</style>
