<template>
    <div>
        <section class="px-4 relative top-20 pb-32 md:pb-40 md:pt-20 pt-12">
            <div class="container">
                <div class="max-w-[960px] w-full mx-auto">
                    <!--
                     <h1 class="font-bold text-agra-green-dark font-montserrat mb-10 lg:mb-12 text-3xl md:text-5xl w-full">
                        Knowledge Base
                    </h1>
                    -->
                    <div v-for="(category, index) in categories"
                         :key="`category-${index}`"
                         :id="'category-' + index"
                         class="pb-12 mb-12"
                    >
                        <h2 class="text-2xl text-agra-green-dark md:text-4xl font-semibold border-b-2 border-agra-green pb-8 mb-12">
                            {{ category }}
                        </h2>
                        <div class="grid gap-10">
                            <FAQ v-for="faq in faqs.filter(item => item.categories === category)"
                                 :key="faq.id"
                                 :faq="faq"
                                 :id="`faq-${faq.id}`"
                            >
                            </FAQ>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import FAQ from './shared/FAQ.vue'
    import {mapState, mapActions, mapMutations} from 'vuex';

    export default {
        data() {
            return {
                categories: []
            }
        },
        computed: {
            ...mapState([
                'faqs',
                'activeFaq'
            ]),
            activeFaq() {
                return this.$route.query.faq;
            },
            activeCategory() {
                return this.$route.query.category;
            }
        },
        components: {
            FAQ
        },
        async mounted() {
            await this.fetchFaqs();
            this.setActiveFaqParams();
            this.setActiveCategory();
        },
        watch: {
            'faqs': 'getCategories'
        },
        methods: {
            ...mapMutations([
                'setActiveFaq'
            ]),
            ...mapActions([
                'fetchFaqs'
            ]),
            setActiveFaqParams() {
                let question = this.activeFaq;
                if (!question) return;

                let wantedFaq = this.faqs.find(item => item.id === question);

                this.setActiveFaq(wantedFaq);
                this.setActiveFaqInternal();
            },
            setActiveFaqInternal() {
                if (!this.activeFaq) return;

                this.$router.replace({
                    name: 'Knowledgebase',
                    query: {
                        ...this.$route.query,
                        question: this.activeFaq.id
                    }
                })
                    .catch(err => {
                        console.log(err);
                    });
                //this.$nextTick(() => {
                const faq = document.getElementById(`faq-${this.activeFaq.id}`);
                // const elementPosition = faq.getBoundingClientRect().top;
                // const offsetPosition = elementPosition - 20;
                faq.scrollIntoView({behavior: 'smooth'});
                //});
            },
            setActiveCategory() {
                if (this.activeCategory === 'use') {
                    setTimeout(() => {
                        const category = document.getElementById(`category-2`);
                        category.scrollIntoView({behavior: 'smooth'});
                    }, 1000);
                }
            },
            getCategories() {
                if (this.faqs.length) {
                    let categories = [];

                    for (let i = 0; i < this.faqs.length; i++) {
                        categories = categories.concat(this.faqs[i].categories);
                    }

                    this.categories = categories.filter((v, i, a) => a.indexOf(v) === i);
                }
            },
        }
    }
</script>

